import React from "react";
import { Image, Text } from "@sitecore-jss/sitecore-jss-react";
import Link from "../../../core/Components/Link";
import useIsMobile from "../../../utils/useIsMobile";

const MarketingConsentComponent = ({ fields }) => {
  const isMobile = useIsMobile(1024);

  return (
    <div
      className="marketing-consent-container"
      style={{
        backgroundImage: isMobile
          ? "none"
          : `url(${fields.DesktopImage.value?.src || ""})`,
      }}
    >
      <div className="marketing-consent l-container">
        <div className="text-block-container">
          <h2 className="description-text">
            <Text field={fields.Text} />
          </h2>
          <Link field={fields.getStarted} className="cta-button tertiary" />
        </div>
        <div className="image-block-container">
          <Image field={fields.MobileImage} />
        </div>
      </div>
    </div>
  );
};

export default MarketingConsentComponent;
